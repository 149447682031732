var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"breadcrumbsItems":_vm.breadcrumbs,"actions":_vm.actions,"inactiveFilterValue":_vm.inactiveFilterValue,"showInactiveFilter":""},on:{"switch-inactive":_vm.switchInactive,"create-new":_vm.newProfileView}},[_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.profileViews,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"item-class":_vm.itemRowStyle,"footer-props":_vm.$defaultDataTableFooterProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tenant_id",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.getTenantTitle(item.tenant_id))+" ")])]}},{key:"item.customer_properties",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.getCustomerPropertiesTitles(item.customer_properties))+" ")])]}},{key:"item.user_groups",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.getUserGroupsTitles(item.user_groups))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editProfileView(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])])]}}])}),_c('DefaultModal',{ref:"modal",attrs:{"title":_vm.getModalTitle()},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DynamicFormContent',{attrs:{"fields":_vm.fields},on:{"tenant-change":_vm.handleSelectedTenant}})]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }