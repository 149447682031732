<template>

  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newProfileView"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="profileViews"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :item-class="itemRowStyle"
        :footer-props="$defaultDataTableFooterProps"
      >

        <template v-slot:item.tenant_id="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getTenantTitle(item.tenant_id) }}
          </span>
        </template>

        <template v-slot:item.customer_properties="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getCustomerPropertiesTitles(item.customer_properties) }}
          </span>
        </template>

        <template v-slot:item.user_groups="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getUserGroupsTitles(item.user_groups) }}
          </span>
        </template>
      
        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editProfileView(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          
        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent 
            :fields="fields"
            @tenant-change="handleSelectedTenant"
          ></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }

</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import CustomerPropertyActions from '@/store/cdp/customer_property/actions-types'
import GroupActions from '@/store/core/group/actions-types'
import ProfileViewActions from '@/store/cdp/profile_view/actions-types'
import ProfileViewMutations from '@/store/cdp/profile_view/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "ProfileView",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('cdp/customer_property', [
      'customerPropertyTitleById'
    ]),

    ...mapState({
      fields: state => state.cdp.profile_view.fields,
      profileViews: state => state.cdp.profile_view.profileViews,
      selectedProfileView: state => state.cdp.profile_view.selectedProfileView,
      inactiveFilterValue: state => state.cdp.profile_view.inactiveFilterValue,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('cdp_settings'),
          prependIcon: 'mdi-cog-outline',
          iconColor: 'primary',
          to: {
            name: "CdpSettings",
            params: { appId: this.$route.params.appId }
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.$t('profile_view'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      options: {},
      itemsLength: 0,
      tenants: [],
      groups: [],
      headers: [
        {text: 'Key', value: 'profile_view_key'},
        {text: 'Title', value: 'profile_view_title'},
        {text: 'Tenant', value: 'tenant_id'},
        {text: 'Customer Properties', value: 'customer_properties'},
        {text: 'Groups', value: 'user_groups'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      actions: [
        {
          text: "create_new",
          eventToEmit: "create-new",
        }
      ],
    }
  },

  mounted() {
    this.loading = true
    this.[ProfileViewMutations.SET_SELECTED_PROFILE_VIEW](null)
  
    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[ProfileViewMutations.SET_FIELD_TENANTS](tenants.items)

        this.tenants = [...tenants.items]

        this.[GroupActions.GET_ALL_GROUPS]()
          .then((groups) => {
            this.groups = [...groups.items]

            this.[CustomerPropertyActions.GET_ALL]()
            .then(() => {
              this.reloadData()
            })
          })
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('cdp/customer_property', [
      CustomerPropertyActions.GET_ALL,
      CustomerPropertyActions.GET_CUSTOMER_PROPERTIES_BY_TENANT,
    ]),

    ...mapActions('core/group', [
      GroupActions.GET_ALL_GROUPS,
    ]),

    ...mapActions('cdp/profile_view', [
      ProfileViewActions.GET_ALL,
      ProfileViewActions.SAVE_PROFILE_VIEW,
    ]),

    ...mapMutations('cdp/profile_view', [
      ProfileViewMutations.SET_SELECTED_PROFILE_VIEW,
      ProfileViewMutations.SET_FIELD_TENANTS,
      ProfileViewMutations.SET_FIELD_CUSTOMER_PROPERTIES,
      ProfileViewMutations.SET_FIELD_GROUPS,
      ProfileViewMutations.SET_INACTIVE_FILTER_VAL,
    ]),

    newProfileView() {
      this.[ProfileViewMutations.SET_SELECTED_PROFILE_VIEW](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.[ProfileViewActions.SAVE_PROFILE_VIEW]()
        .then(() => {
          let message = this.selectedProfileView ? 'Profile View edited' : 'Profile View created'

          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))

          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      this.loading = true

      this.[ProfileViewActions.GET_ALL](options)
        .then((result) => {
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    switchInactive() {
      this.[ProfileViewMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)

      this.reloadData()
    },

    editProfileView(profileView) {
      this.[ProfileViewMutations.SET_SELECTED_PROFILE_VIEW](profileView)

      this.handleSelectedTenant(profileView.tenant_id, false)

      this.$refs.modal.openModal()
    },

    getModalTitle() {
      return this.selectedProfileView ? this.$t('Edit') : this.$t('new_profile_view')
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getCustomerPropertiesTitles(customerProperties) {
      let titles = []

      customerProperties.forEach((item) => titles.push(item.customer_property_title))

      return titles.join(', ')
    },

    getUserGroupsTitles(userGroups) {
      let titles = []

      userGroups.forEach((item) => {
        const group = this.groups.find(f => f.user_group_id == item.user_group_id)

        if (group) {
          titles.push(group.group_title)
        }
      })

      return titles.join(', ')
    },

    handleSelectedTenant(tenantId, clearValue = true) {
      this.$refs.modal.submitting = true

      this.[CustomerPropertyActions.GET_CUSTOMER_PROPERTIES_BY_TENANT]({tenantId})
        .then((customerProperties) => {

          this.[ProfileViewMutations.SET_FIELD_CUSTOMER_PROPERTIES]({
            customerProperties: customerProperties.items,
            clearValue,
          })

          const options = {...options, 'filter[tenant]' : tenantId}

          this.[GroupActions.GET_ALL_GROUPS](options)
          .then((groups) => {
            this.[ProfileViewMutations.SET_FIELD_GROUPS]({groups: groups.items, clearValue})

            this.$refs.modal.submitting = false
          })
          .catch(() => this.$refs.modal.submitting = false)
          
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    itemRowStyle(item) {
      return item.active ? '': 'inactive-row'
    }
  }
}

</script>